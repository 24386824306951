<!-- Modal -->
<h1 mat-dialog-title>Add Cluster Node</h1>
<form class="form" [formGroup]="form" (ngSubmit)="onSubmit(form.value)" autocomplete="off">

    <div mat-dialog-content>
        <mat-tab-group>
    <!--      <mat-tab label="Host">-->
    <!--        <ng-template mat-tab-label>-->
    <!--          <mat-icon>computer</mat-icon>&nbsp;Host-->
    <!--        </ng-template>-->
    <!--        <mat-card>-->
    <!--            <mat-card-content>-->
    <!--                <mat-form-field>-->
    <!--                    <input matInput placeholder="Host IP">-->
    <!--                </mat-form-field>-->

    <!--                <mat-form-field>-->
    <!--                    <input matInput placeholder="SSH username">-->
    <!--                </mat-form-field>-->

    <!--                <mat-form-field>-->
    <!--                    <input matInput placeholder="SSH private key">-->
    <!--                </mat-form-field>-->

    <!--            </mat-card-content>-->
    <!--        </mat-card>-->
    <!--      </mat-tab>-->
          <mat-tab label="Cloud">
            <ng-template mat-tab-label>
              <mat-icon>cloud</mat-icon>&nbsp;Cloud Node
            </ng-template>
            <mat-card>
                <mat-card-content>
                    <mat-form-field class="vm_type_selection">
                        <mat-select id="id_vm_type" [formControl]="vmTypeCtrl">
                            <mat-select-trigger>
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div fxFlex="25">{{ vmTypeCtrl.value?.name }}</div>
                                    <div fxFlex="25" fxLayoutAlign="center center">
                                        <div fxFlex="75"><span class="pull-right">{{ vmTypeCtrl.value?.vcpus }} VCPUs</span></div>
                                        <div fxFlex="25">&nbsp;<mat-icon>memory</mat-icon></div>
                                    </div>
                                    <div fxFlex="25" fxLayoutAlign="center center">
                                        <div fxFlex="75"><span class="pull-right">{{ vmTypeCtrl.value?.ram }} GB RAM</span></div>
                                        <div fxFlex="25">&nbsp;<mat-icon>sim_card</mat-icon></div>
                                    </div>
                                    <div fxFlex="25" fxLayoutAlign="center center">
                                        <div fxFlex="75"><span class="pull-right">{{ vmTypeCtrl.value?.size_total_disk }} GB Disk</span></div>
                                        <div fxFlex="25">&nbsp;<mat-icon>storage</mat-icon></div>
                                    </div>
                                </div>
                            </mat-select-trigger>
                            <mat-option disabled>
                                <div class="vmtype row" class.xs="vmtype column">
                                    <div class="vmtype_item_25">VM Type</div>
                                    <div class="spec row">
                                        <div fxLayoutAlign="center">CPUs</div>
                                    </div>
                                    <div class="spec row">
                                        <div fxLayoutAlign="center">RAM (GB)</div>
                                    </div>
                                    <div class="spec row">
                                        <div fxLayoutAlign="center">Total Disk (GB)</div>
                                    </div>
                                </div>
                            </mat-option>
                            <!-- Use flex css instead of angular flexbox due to performance:-->
                            <!-- https://github.com/angular/flex-layout/wiki/Performance-Considerations-->
                            <mat-option *ngFor="let vmType of vmTypeObs | async | orderBy: 'name'" [value]="vmType">
                                <div class="vmtype row" class.xs="vmtype column">
                                    <div class="vmtype_item_25">{{ vmType.name }}</div>
                                    <div class="spec row">
                                        <div class="spec_item_50"><span class="pull-right">{{ vmType.vcpus }}</span></div>
                                        <div class="spec_item_50">&nbsp;<mat-icon>memory</mat-icon></div>
                                    </div>
                                    <div class="spec row">
                                        <div class="spec_item_50"><span class="pull-right">{{ vmType.ram }}</span></div>
                                        <div class="spec_item_50">&nbsp;<mat-icon>sim_card</mat-icon></div>
                                    </div>
                                    <div class="spec row">
                                        <div class="spec_item_50"><span class="pull-right">{{ vmType.size_total_disk }}</span></div>
                                        <div class="spec_item_50">&nbsp;<mat-icon>storage</mat-icon></div>
                                    </div>
                                </div>
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="vmTypeCtrl.hasError('required')">Instance type is <strong>required</strong></mat-error>
                        <mat-placeholder><i *ngIf="vmTypeHelp.includes('Retrieving')" class="fa fa-spinner fa-spin fa-fw"></i>
                            {{ vmTypeHelp }}</mat-placeholder>
                    </mat-form-field>

                </mat-card-content>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
    </div>
    <div mat-dialog-actions class="pull-right">
        <button mat-button mat-stroked-button mat-dialog-close="cancel" tabindex="1">Cancel</button>
        <button mat-button mat-raised-button color="primary" type="submit" tabindex="2" [disabled]="submitPending || !form.valid">Add</button>
    </div>
</form>
