<div id="container" class="wrapper">
    <nav class="main" [class.primary-is-mobile]="mobileQuery.matches">
      <mat-toolbar color="primary" class="primary-toolbar" fxFlex fxLayout="row wrap">
        <h1>
            <a mat-button routerLink="/" aria-label="CloudMan"><img src="assets/images/logo_gvl.svg" style="max-height: 48px;"/></a>
        </h1>
        <!-- Page title insertion point -->
        <h1 style="padding-left: 20px;">{{ getPageTitle() }}</h1>
        <span class="flex-spacer"></span>
        <a mat-button routerLink="/system_overview" routerLinkActive="active" aria-label="Overview">
            <mat-icon>home</mat-icon> Overview
        </a>
        <span *ngIf="loggedIn==false">
            <a mat-button routerLink="/login"><mat-icon>login</mat-icon> Login</a>
        </span>
        <span *ngIf="loggedIn">
            <a mat-button aria-label="Overview" [matMenuTriggerFor]="appMenu">
                <mat-icon>account_circle</mat-icon> User
                <span *ngIf="currentUser?.username">({{ currentUser?.username }})</span>
            </a>
            <mat-menu #appMenu="matMenu">
                <button mat-menu-item>
                    <a mat-button routerLink="/logout">
                        <mat-icon>login</mat-icon> Logout
                    </a>
                </button>
            </mat-menu>
        </span>
      </mat-toolbar>
    </nav>

    <div [@routerTransition]="getState(o)">
        <!-- The router-outlet displays the template for the current component based on the URL -->
        <router-outlet #o="outlet"></router-outlet>
    </div>
</div>

<!-- Footer -->
<footer id="footer" class="footer">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <mat-card fxFlex="25">
            <mat-card-header>
                <mat-card-title><i class="fa fa-ticket"></i> About </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul>
                    <li> <a routerLink="/about" routerLinkActive="active"> About </a> </li>
                    <li> <a href="{{ getSupportContactLink() }}"> Contact Us </a> </li>
                </ul>
            </mat-card-content>
        </mat-card>
        <mat-card fxFlex="30">
            <mat-card-header>
                <mat-card-title><i class="fa fa-code"></i> Developer </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ul>
                    <li> <a href="{{ getDeveloperAPILink() }}"> Developer API (beta) </a> </li>
                    <li> <a href="https://github.com/galaxyproject/cloudman"> Source Code <i class="fa fa-github"></i></a> </li>
                </ul>
            </mat-card-content>
        </mat-card>
        <span class="flex-spacer"></span>
        <mat-card fxFlex="20">
            <mat-card-header>
                <mat-card-title> </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <img src="assets/images/logo_cloudman.svg" alt="cloudman" style="height: 50px; width: 100%;" />
            </mat-card-content>
        </mat-card>
    </div>
    <!--/.footer-->
</footer>
