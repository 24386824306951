<div>
    <form class="form">
        <mat-expansion-panel #nodePanel>
            <mat-expansion-panel-header class="custom-expansion-header">
                <mat-panel-title>
                    Nodes
                </mat-panel-title>
                <mat-panel-description>
                    Expand to view nodes in your cluster
                    &nbsp;&nbsp;<div><i *ngIf="fetchingNodes" class="fa fa-spinner fa-spin fa-fw"></i></div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <br />
                <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between stretch">
                    <div fxFlex="100">
                        <div fxFlex>Name</div>
                        <div fxFlex>Status</div>
                        <div fxFlex>Uptime</div>
                        <div fxFlex>Type</div>
                        <div fxFlex>IP</div>
                        <div fxFlex>AutoScaler Managed</div>
                        <div fxFlex>Actions</div>
                    </div>
                    <div fxFlex="100">
                        <hr />
                    </div>
                    <div fxFlex="100" *ngFor="let node of nodeObservable | async;">
                        <div fxFlex>{{ node.name }}</div>
                        <div fxFlex>
                            <div [ngSwitch]="node.deployment.latest_task?.action">
                                <div *ngSwitchCase="'LAUNCH'">
                                    <div [ngSwitch]="node.deployment.latest_task?.status">
                                      <div *ngSwitchCase="'SUCCESS'">
                                        <p color="primary"><i class="fa fa-bolt fa-fw"></i> RUNNING</p>
                                      </div>
                                      <p *ngSwitchCase="'FAILURE'" color="warn">FAILURE
                                         <br />{{ node.deployment.latest_task?.result?.exc_message }}
                                      </p>
                                      <p *ngSwitchCase="'UNKNOWN'" color="accent">FAILURE
                                         <br />{{ node.deployment.latest_task?.result?.exc_message }}
                                      </p>
                                      <p *ngSwitchCase="'PENDING'" color="link">PENDING</p>
                                      <p *ngSwitchCase="'PROGRESSING'" class="text-info"><i class="fa fa-cog fa-spin fa-fw"></i> PROGRESSING
                                        <br />{{ node.deployment.latest_task?.result?.action }}
                                      </p>
                                    </div>
                                </div>
                                <div *ngSwitchCase="'DELETE'">
                                    <div [ngSwitch]="node.deployment.latest_task?.status">
                                      <div *ngSwitchCase="'SUCCESS'">
                                        <p color="primary"><i class="fa trash fa-fw"></i> DELETED</p>
                                      </div>
                                      <p *ngSwitchCase="'FAILURE'" color="warn">DELETE FAILED
                                         <br />{{ node.deployment.latest_task?.result?.exc_message }}
                                      </p>
                                      <p *ngSwitchCase="'UNKNOWN'" color="accent">DELETE FAILED
                                         <br />{{ node.deployment.latest_task?.result?.exc_message }}
                                      </p>
                                      <p *ngSwitchCase="'PENDING'" color="link"><i class="fa fa-cog fa-spin fa-fw"></i> DELETING</p>
                                      <p *ngSwitchCase="'PROGRESSING'" class="text-info"><i class="fa fa-cog fa-spin fa-fw"></i> PROGRESSING
                                        <br />{{ node.deployment.latest_task?.result?.action }}
                                      </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxFlex>{{ calculateUptime(node, currentTimer | async) }} ago</div>
                        <div fxFlex>{{ node.deployment.application_config?.config_cloudlaunch?.vmType }}</div>
                        <div fxFlex>{{ node.deployment.launch_task?.result?.cloudLaunch?.publicIP }}</div>
                        <div fxFlex><p class="text-success" *ngIf="node.autoscaler"><i class="fa fa-check-circle-o"></i></p></div>
                        <div fxFlex>
                            <a class="text-info" href="javascript:void(0);" (click)="deleteNode(node)" title="Delete"><i class="fa fa-remove"></i></a>
                        </div>
                    </div>
                </div>
                <br /><br />
                <button mat-button type="button" matTooltip="Add node" (click)="openAddNodeDialog(element)" *ngIf="loginService.getCurrentUser().isAdmin()">
                    <mat-icon>add</mat-icon> Manually Add Node
                </button>
            </ng-template>
        </mat-expansion-panel>
    </form>
</div>
