<div class="row">
    <div class="col-md-12">
        <h3>
            <ng-content select="panel-header"></ng-content>
        </h3>
        <div class="panel-body">
            <!-- Panel body insertion point -->
            <ng-content select="panel-body"></ng-content>
        </div>
    </div>
</div>
