<div *ngIf="clusterObs | async as cluster">
    <mat-expansion-panel expanded="true" class="admin-links-panel">
        <mat-expansion-panel-header class="custom-expansion-header">
            <mat-panel-title>
                Admin Links
            </mat-panel-title>
            <mat-panel-description>
                Links to related administration consoles
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div fxFlex fxLayout="row wrap" fxLayoutAlign="start stretch">
                <mat-card>
                    <mat-card-header>
                        <img mat-card-avatar
                             src="assets/images/logo_keycloak.svg"/>
                        <mat-card-title><a href="{{ getKeycloakPath() }}">Keycloak</a></mat-card-title>
                        <mat-card-subtitle>Add/remove users, assign roles to users, and manage SSO.</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-header>
                        <img mat-card-avatar src="assets/images/logo_grafana.svg"/>
                        <mat-card-title><a href="/grafana">Grafana</a></mat-card-title>
                        <mat-card-subtitle>Drill into detailed statistics of the Kubernetes cluster and apps.
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                    </mat-card-content>
                </mat-card>
            </div>
        </ng-template>
    </mat-expansion-panel>
    <br/>
    <cluster-autoscaler-list [cluster]="cluster"></cluster-autoscaler-list>
    <br/>
    <cluster-node-list></cluster-node-list>
    <br/>
    <mat-expansion-panel expanded="true" class="grafana-expansion-panel">
        <mat-expansion-panel-header class="custom-expansion-header">
            <mat-panel-title>
                Statistics
            </mat-panel-title>
            <mat-panel-description>
                <div *ngIf="show_animation"><i class="fa fa-spinner fa-spin fa-fw"></i> Loading...</div>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
            <iframe #grafanaframe
                    src="/grafana/d/cm_cluster_dashboard/nodes?refresh=10s&orgId=1&kiosk&var-Node=All&theme=light"
                    width="100%" height="1140px" frameborder="0" marginheight="0" marginwidth="0" iframeAutoHeight
                    (load)="onGrafanaLoaded(event)"></iframe>
        </div>
    </mat-expansion-panel>
</div>
