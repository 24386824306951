<!-- Modal -->
<h1 mat-dialog-title>Add Applications</h1>
<div mat-dialog-content>
    <div class="chart-container">
        <section *ngFor="let install_tpl of installTemplatesObs | async; index as i">
            <mat-checkbox name="template_action" [formControl]="installTemplateCtrls.controls[i].controls['action']">{{ install_tpl.display_name }}</mat-checkbox>
        </section>
    </div>
</div>
<div mat-dialog-actions class="pull-right">
    <button mat-button mat-stroked-button mat-dialog-close="cancel" tabindex="1">Cancel</button>
    <button mat-button mat-raised-button mat-dialog-close="save" color="primary" tabindex="2">Update</button>
</div>
