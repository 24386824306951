<mat-sidenav-container class="primary-container-sidenav-container"
                     [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
          <!-- Page body insertion point -->
        <ng-content select="page-body"></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
