<!-- Modal -->
<h1 mat-dialog-title>Edit Application Configuration</h1>
    <div mat-dialog-content>
        <mat-tab-group>
            <mat-tab *ngFor="let config of configs | keyvalue: sortConfigs; trackBy: trackByKey;" [label]="config.key">
                <ng-template mat-tab-label>
                    <app-inline-edit required="true" [ngModel]="config.key"
                                     (ngModelChange)="configChanged(config.key, $event)"></app-inline-edit>
                </ng-template>
                <div ace-editor [(text)]="configs[config.key]"
                     [mode]="config.key.endsWith('xml') ? 'xml' : 'yaml'"
                     style="min-height: 500px; width:100%; overflow: auto;"></div>
            </mat-tab>
            <mat-tab disabled>
                <ng-template mat-tab-label>
                    <button mat-icon-button (click)="addNewTab()">
                        <mat-icon>add</mat-icon>
                    </button>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
<div mat-dialog-actions class="pull-right">
    <button mat-button mat-stroked-button mat-dialog-close="cancel" tabindex="1">Cancel</button>
    <button mat-button mat-raised-button mat-dialog-close="save" color="primary" tabindex="2">Save</button>
</div>
